export class UploadService {
    list = [];
    OnEnd = null;
    OnProgress = null;
    Data = null;

    constructor() {
        this.list = [];
    }


    Upload = (file, url, token = "") => {
        let xhr = new XMLHttpRequest();
        let fd = new FormData();
        let progress = 0;

        xhr.open('POST', url, true);
        xhr.setRequestHeader("Authorization", token);
        xhr.onreadystatechange = () => {
            if (xhr.readyState == xhr.DONE && xhr.status == 200) {
                this.PopUpload(url, token);
                if (this.OnEnd) this.OnEnd({ img: xhr.response });
            }
        };

        xhr.upload.addEventListener("progress", function (e) {
            progress = parseInt(e.loaded / e.total * 100);
            if (this.OnProgress) this.OnProgress({ percent: progress });
        }, false);

        //console.log(file.name);
        fd.append(file.name, file);
        if(this.Data !== null) {
            let _data = JSON.stringify(this.Data);
            fd.append("json", _data);
        }
        xhr.send(fd);
    }



    Queue = (item) => {
        this.list.push(item)
        //console.log(this.list);
    }


    Start = (url, callbackprogress, callbackend) => {
        this.OnProgress = callbackprogress;
        this.OnEnd = callbackend;
        this.PopUpload(url);
    }


    PopUpload = (url, token = "") => {
        if (this.list.length > 0) {
            let file = this.list.pop();
            this.Upload(file, url, token);
        }
    }
}