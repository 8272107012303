import React, { createContext } from 'react';

import useHub from './hooks/useHub';

const HubContext = createContext();

function HubProvider({ children }) {
    const {
        Hub
    } = useHub();

    return (
        <HubContext.Provider value={{ Hub }}>
            {children}
        </HubContext.Provider>
    );
}

export { HubContext, HubProvider };