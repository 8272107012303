import { useState, useEffect, useRef } from 'react';
import { Uuid } from '../../scripts/StringUtils';
import { WaitObject } from '../../scripts/Wait';

export default function useInvalidator() {
    const [Invalidator, setInvalidator] = useState({});
    const block = useRef({});

    
    async function InvalidateStream(name)
    {
        if(block.current[name] === undefined) {
            block.current = Object.assign({[name]: true}, block.current);
        } else if(block.current[name] === true) {
            return;
        } else if(block.current[name] === false) {
            block.current[name]  = true;
        }

        block.current[name] = true;

        let uuid = localStorage.getItem('invalidators_' + name);

        let _tmp = {...Invalidator};
        if(_tmp[name]) {
            _tmp[name] = uuid;
        } else {
            _tmp = Object.assign({[name]: uuid}, _tmp);
        }

        setInvalidator(_tmp);
        block.current[name] = false;
        return true;
        
    }

    async function Invalidate(name)
    {
        
        if(block.current[name] === undefined) {
            block.current = Object.assign({[name]: true}, block.current);
        } else if(block.current[name] === true) {
            return;
        } else if(block.current[name] === false) {
            block.current[name] = true;
        }

        block.current[name] = true;

        let uuid = Uuid();
        localStorage.setItem('invalidators_' + name, uuid);

        let _tmp = {...Invalidator};
        if(_tmp[name]) {
            _tmp[name] = uuid;
        } else {
            _tmp = Object.assign({[name]: uuid}, _tmp);
        }

        
        setInvalidator(_tmp);
        
        block.current[name] = false;
        return true

    }




    return { Invalidate, Invalidator, InvalidateStream };
}