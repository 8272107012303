import React, { createContext } from 'react';

import useInvalidator from './hooks/useInvalidator';

const InvalidatorContext = createContext();

function InvalidatorProvider({ children }) {
    const {
        Invalidate, Invalidator, InvalidateStream
    } = useInvalidator();

    return (
        <InvalidatorContext.Provider value={{ Invalidate, Invalidator, InvalidateStream }}>
            {children}
        </InvalidatorContext.Provider>
    );
}

export { InvalidatorContext, InvalidatorProvider };