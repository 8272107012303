export const TimeAgo = (time, local, raw) => {
    if (!time) return "não realizado";

    time = time.replace(/_/g, ':');

    if (!local) {
        (local = Date.now())
    }

    if (time instanceof Date) {
        time = time.getTime();
    } else if (typeof time === "string") {
        time = new Date(time).getTime();
    }

    if (local instanceof Date) {
        local = local.getTime();
    } else if (typeof local === "string") {
        local = new Date(local).getTime();
    }

    if (typeof time !== 'number' || typeof local !== 'number') {
        return;
    }

    var
        offset = Math.abs((local - time) / 1000),
        span = [],
        MINUTE = 60,
        HOUR = 3600,
        DAY = 86400,
        WEEK = 604800,
        MONTH = 2629744,
        YEAR = 31556926,
        DECADE = 315569260;

    if (offset <= MINUTE) span = ['', raw ? 'now' : 'agora'];
    else if (offset < (MINUTE * 60)) span = [Math.round(Math.abs(offset / MINUTE)), 'min'];
    else if (offset < (HOUR * 24)) span = [Math.round(Math.abs(offset / HOUR)), 'hr'];
    else if (offset < (DAY * 7)) span = [Math.round(Math.abs(offset / DAY)), 'dia'];
    else if (offset < (WEEK * 52)) span = [Math.round(Math.abs(offset / WEEK)), 'semana'];
    else if (offset < (YEAR * 10)) span = [Math.round(Math.abs(offset / YEAR)), 'ano'];
    else if (offset < (DECADE * 100)) span = [Math.round(Math.abs(offset / DECADE)), 'decada'];
    else span = ['', 'a muito tempo'];

    span[1] += (span[0] === 0 || span[0] > 1) ? 's' : '';
    span = span.join(' ');

    if (raw === true) {
        return span;
    }
    if (span.indexOf("agora") > -1) return span;
    return (time <= local) ? span + ' atras' : 'em ' + span;
}